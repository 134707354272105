<script setup lang="ts">
    // https://www.algolia.com/doc/api-reference/widgets/refinement-list/vue/
    import RefinementList from 'vue-instantsearch/vue3/es/src/components/RefinementList.vue';

    import {truncateText} from '@/helpers/truncateText';

    const props = defineProps({
        attribute: {
            type: String,
            required: true,
        },
        transformItems: {
            type: Function,
            required: false,
        },
        label: {
            type: String,
            required: true,
        },
        limit: {
            type: Number,
            required: false,
            default: 50,
        },
        labels: {
            type: Object,
            required: false,
            default: () => ({}),
        },
        isExpanded: {
            type: Boolean,
            required: false,
            default: false,
        },
    });

    const valuesToRemove = [
        'm1',
        'ral',
        'doc',
        'dbb',
        'Wall & Room niche', //C290
        'Wallpaper Fiona', //C200
        'Wallpaper External', //C210
    ];

    function sortByCount(items) {
        return items.sort((a, b) => b.count - a.count);
    }

    function sortByLabel(items) {
        return items
            .sort((a, b) => a.label.localeCompare(b.label))
            .filter(item => !valuesToRemove.includes(item.value));
    }

    const { logEvent } = useMetrics();

    function refineWithLogging(
        algoliaRefineCallback: CallableFunction,
        value: any,
        isRefined: Boolean
    ) {
        logEvent('filter_by_attribute', props.attribute, {
            value,
            was_refined: isRefined ? 'yes' : 'no',
        });
        algoliaRefineCallback(value);
    }
</script>

<template>
    <RefinementList
        :attribute="attribute"
        :transformItems="transformItems || sortByLabel"
        :limit="limit"
        operator="and"
    >
        <template
            v-slot="{
                items,
                isShowingMore,
                isFromSearch,
                canToggleShowMore,
                refine,
                createURL,
                toggleShowMore,
                searchForItems,
                sendEvent,
            }"
        >
            <details
                class="overflow-hidden rounded-lg border bg-white text-[#6B7280] [&_summary::-webkit-details-marker]:hidden"
                :open="isExpanded"
            >
                <summary
                    class="flex cursor-pointer items-center justify-between gap-2 p-4 transition"
                >
                    <span
                        class="text-sm"
                        :class="{
                            'font-bold': items.filter(i => i.isRefined).length,
                            'font-semibold': items.filter(i => i.isRefined).length,
                        }"
                    >
                        {{ label }}
                    </span>

                    <span class="transition group-open:-rotate-180">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            class="h-4 w-4"
                        >
                            <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                            />
                        </svg>
                    </span>
                </summary>

                <div class="border-t border-[##6B7280] bg-base-100">
                    <header class="flex items-center justify-between p-4">
                        <span class="text-sm">
                            {{ items.filter(i => i.isRefined).length }} Selected
                        </span>

                        <button
                            @click="
                                items.forEach(
                                    i =>
                                        i.isRefined &&
                                        refineWithLogging(refine, i.value, i.isRefined)
                                )
                            "
                            type="button"
                            class="text-sm underline underline-offset-4"
                        >
                            Reset
                        </button>
                    </header>

                    <ul class="space-y-1 border-t border-[##6B7280] p-4">
                        <li
                            v-for="item in items"
                            :key="item.value"
                            :class="{
                                'opacity-50': !item.count,
                            }"
                        >
                            <label class="">
                                <input
                                    type="checkbox"
                                    @change="
                                        refineWithLogging(
                                            refine,
                                            item.value,
                                            item.isRefined
                                        )
                                    "
                                    class="checkbox mr-2 h-4 w-4"
                                    v-model="item.isRefined"
                                />

                                <span class="text-sm">
                                    {{ truncateText(labels[item.label] || item.label) }}
                                </span>
                            </label>
                        </li>
                    </ul>
                    <!-- <button
                        v-if="items.length >= limit"
                        class="btn btn-info btn-sm m-4"
                        @click="toggleShowMore"
                    >
                        <span v-if="isShowingMore">Show less</span>
                        <span v-else>Show more</span>
                    </button> -->
                </div>
            </details>
        </template>
    </RefinementList>
</template>
