export const companyCertificates = [
    {
        id: 1,
        name: 'ISO 14001',
        url: 'https://www.flugger.com/globalassets/flugger.com/files/pdf/koncerncorporate/dk015171-1-fl%C3%BCgger-group-as-iso-14001-ukas-uk-25-02-2022.pdf',
    },
    {
        id: 2,
        name: 'ISO 9001',
        url: 'https://www.flugger.com/globalassets/flugger.com/files/pdf/koncerncorporate/dk015170-1-fl%C3%BCgger-group-as-iso-9001-ukas-uk-24-02-22.pdf',
    },
    {
        id: 3,
        name: 'Nordic Ecolabel license, Paint & varnish',
        url: 'https://www.flugger.com/globalassets/flugger.com/files/pdf/koncerncorporate/3096-0024-3.100-fl%C3%BCgger-group-as-sverige_certifikat_english.pdf',
    },
    {
        id: 4,
        name: 'Nordic Ecolabel license, Filler and adhesive',
        url: 'https://www.flugger.com/globalassets/flugger.com/files/pdf/koncerncorporate/3097-0008-2.61-fl%C3%BCgger-group-as-sverige_certifikat_english.pdf',
    },
    {
        id: 5,
        name: 'Nordic Ecolabel license, Exterior products',
        url: 'https://www.flugger.com/globalassets/flugger.com/files/pdf/koncerncorporate/3097-0029-2.63-fl%C3%BCgger-group-as-sverige_certifikat_english.pdf',
    },
    {
        id: 6,
        name: 'EU Ecolabel license',
        url: 'https://www.flugger.com/globalassets/flugger.com/files/pdf/koncerncorporate/eu-ecolabel-iicense-certificate-fl%C3%BCgger_oct-2018.pdf',
    },
];
